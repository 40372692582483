import { tap } from 'rxjs';
import {
  assertIsHeimdallOkTokenSet,
  TokenBundleIdp,
  TokenSetHeimdallStateless,
} from './internal.js';
import { createRetryFetch$, RetryConfig } from './rx-helpers.js';
import { createTokenSwapParams } from './url-helper.js';

export function createStatelessToken$(
  bundle: TokenBundleIdp,
  heimdallExtraTrace?: string,
  retryConfig: RetryConfig = {}
) {
  const { idpConfig, tokenSet: tokenSetIdp } = bundle;
  const { heimdallTokenEndpoint, swapTokenType, clientUid } = idpConfig;
  const subjectToken = tokenSetIdp[swapTokenType] as string;

  const swapParams = createTokenSwapParams(
    subjectToken,
    clientUid,
    swapTokenType,
    ['stateless']
  );

  return createRetryFetch$<TokenSetHeimdallStateless>(
    heimdallTokenEndpoint,
    {
      method: 'POST',
      body: swapParams,
      mode: 'cors',
      addHeimdallTrace: true,
      ...(heimdallExtraTrace ? { heimdallExtraTrace } : {}),
    },
    retryConfig
  ).pipe(tap((tokenSet) => assertIsHeimdallOkTokenSet(tokenSet)));
}
